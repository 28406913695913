import triogif from '../assets/img/triocatering.gif'
import admingif from '../assets/img/adminpanel.gif'
import buffergif from '../assets/img/bufferclone.gif'
import portfoliogif from '../assets/img/m-portfolio.gif'
import przeptig from '../assets/img/przepgif.gif'
import dagartgif from '../assets/img/dagartgif.gif'
import backtogif from '../assets/img/backtogif.gif'
import investclagif from '../assets/img/investclagif.gif'
import inmesugif from '../assets/img/inmesugif.gif'
import inregigif from '../assets/img/inregigif.gif'

/*
*  buffer clone 2022
*  admin panel 2022
*  trio catering 2023
*  prze-piorka 2023
*  dagartstudio 2023
*  back to the kraft 2023
*  investcla 2023-2024
*  inregi 2023-2024 trwa
*  inmesu 2024
*  holarsys 2024 w trakcie realizacji
*
*
* */

export const dataslider = [
    {
        id: 1,
        img: inregigif,
        name2: "inregiCurrently",
        trans: "portfolioInregi",
        tech: "React, TypeScript, Redux Toolkit, Styled-Components, Axios, HTML5, CSS, i18n, Vitest, React Testing Library, CI/CD, Git, GitLab, RWD, Photoshop",
        code: "",
        video: "https://www.youtube.com/watch?v=-tR06tB4hD0",
        live: "https://www.inregi.com/"
    },
    {
        id: 2,
        img: inmesugif,
        name2: "InMesu (2024)",
        trans: "portfolioInmesu",
        tech: "WordPress",
        code: "",
        live: "https://inmesu.pl"
    },
    {
        id: 3,
        img: investclagif,
        name2: "CLA Invest (2023-2024)",
        trans: "portfolioInvest",
        tech: "WordPress",
        code: "",
        live: "https://investcla.pl"
    },
    {
        id: 4,
        img: backtogif,
        name2: "Back to the Kraft (2023)",
        trans: "portfolioBack",
        tech: "WordPress",
        code: "",
        live: "https://backtothekraft.pl"
    },
    {
        id: 5,
        img: dagartgif,
        name2: "DagArt Studio (2023)",
        trans: "portfolioDag",
        tech: "WordPress",
        code: "",
        live: "https://dagartstudio.pl/"
    },
    {
        id: 6,
        img: przeptig,
        name2: "Prze Piórka (2023)",
        trans: "portfolioPrzep",
        tech: "WordPress",
        code: "",
        live: "https://prze-piorka.com/"
    },
    {
        id: 7,
        img: triogif,
        name: "Trio Catering (2022)",
        trans: "portfolioTrio",
        tech: "React, Styled-Components",
        code: "https://github.com/MarekPatyna/Trio-Catering",
        live: "https://trio-catering.pl"
    },
    {
        id: 8,
        img: portfoliogif,
        name2: "portfolioMyName",
        trans: "portfolioMy",
        tech: "React, Styled-Components, i18next",
        code: "https://github.com/MarekPatyna/Personal-Portfolio",
        live: "https://marekpatyna.pl"
    },
    {
        id: 9,
        img: admingif,
        name: "Admin Panel (2022)",
        trans: "portfolioAdmin",
        tech: "React, Styled-Components, SyncFusion, API",
        code: "https://github.com/MarekPatyna/Admin-Panel",
        live: "https://marekpatyna-admin-panel.netlify.app"
    },
    {
        id: 10,
        img: buffergif,
        name: "Buffer Clone (2022)",
        trans: "portfolioBuffer",
        tech: "React, Styled-Components",
        code: "https://github.com/MarekPatyna/Buffer-Clone",
        live: "https://marekpatyna-buffer-clone.netlify.app"
    }

]