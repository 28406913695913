import React, { useEffect, useState } from "react"
import {
    Container,
    ContactContainer, GoogleMapDesk
} from "./Contact-styled"
import EmailJs from "../../components/contact/EmailJs"
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";


const Contact = () => {

    const [status, setStatus] = useState(false)
    const [validationAlert, setValidationAlert] = useState(false)
    const position = [52.408674, 16.929450]
    useEffect(() => {
        const timer = setTimeout(() => {
            setStatus(false)
            setValidationAlert(false)
        }, 2000)
        return () => clearTimeout(timer)
    },[status, validationAlert])


    // todo: zamiast mapy dodac FAQ?
    return (
        <Container id="scrollC">
            <ContactContainer>
                <EmailJs
                    status={status}
                    setStatus={setStatus}
                    validationAlert={validationAlert}
                    setValidationAlert={setValidationAlert}
                />
                <GoogleMapDesk data-aos="flip-right" data-aos-easing="linear" data-aos-duration="500">
                    <MapContainer style={{ height: "100%", minHeight: "100%" }} center={[52.4064, 16.9252]} zoom={12} scrollWheelZoom={false}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={position}>
                            <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup>
                        </Marker>
                    </MapContainer>
                </GoogleMapDesk>
            </ContactContainer>

        </Container>
    )
}

export default Contact